import React, { useEffect, useState } from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import "./Product.css";
import db from "../Firebase";
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';

const Product = () => {
    const [data, setData] = useState([]);
    const [cart, setCart] = useState([]);
    const navigate = useNavigate();
    const [product, setProduct] = useState(null);
    const { id } = useParams();
    const [selectedPackage, setSelectedPackage] = useState(null);

    useEffect(() => {
        const unsubscribe = db
            .collection("products_data")
            .orderBy("created_date", "desc")
            .onSnapshot((snapshot) => {
                setData(
                    snapshot.docs
                        .map((doc) => ({
                            id: doc.id,
                            ...doc.data(),
                        }))
                        .filter((product) => product.inStock) 
                );
            });
    
        const savedCart = localStorage.getItem("cart");
        if (savedCart) {
            setCart(JSON.parse(savedCart));
        }
    
        return () => unsubscribe();
    }, []);
    

    useEffect(() => {
        if (cart.length > 0) {
            localStorage.setItem("cart", JSON.stringify(cart));
        }
    }, [cart]);

    useEffect(() => {
        const fetchProductDetails = async () => {
            try {
                const doc = await db.collection('products_data').doc(id).get();

                if (doc.exists) {
                    const productData = doc.data();
                    setProduct(productData);
                    setSelectedPackage(productData.packagingOptions[0]);
                } else {

                }
            } catch (error) {
                console.error('Error getting document:', error);
            }
        };

        fetchProductDetails();
    }, [id]);

    const addProduct = (id) => {
        const selectedPackageUnit = selectedPackage ? `${selectedPackage.packaging} ${selectedPackage.pkgUnit}` : '';
        navigate(`/product_details/${id}`, { state: { package_unit: selectedPackageUnit } });
    };

    return (
        <>
            <div className="product-banner">
                <div className="white-cow-img">
                    <img src="images/white-cow.png" alt="White cow" />
                </div>
                <div>
                    <h1 className="heading-story">Products</h1>
                </div>
                <div className="story-overlay">
                    <Navbar />
                </div>
            </div>

            <div className="third-section-image product-page">
                <div className="container mt-5">
                    <div className="row mt-5">
                        {data.map((item) => (
                            <div key={item.id} className="col-sm-6 col-md-3 col-lg-3 col-xs-6">
                                <div className="card product-item-card">
                                    <img
                                        src={item.image}
                                        className="card-img-top product-item-image"
                                        alt={item.productName}
                                    />
                                    <div className="card-body product-item-name text-center">
                                        <h5>{item.productName}</h5>
                                    </div>
                                    <div className="product-details">
                                        <div className="item-1">
                                            {item.packagingOptions.map(pkg => (
                                                <button
                                                    key={pkg.packaging}
                                                    className="frequency-button"
                                                    onClick={() => setSelectedPackage(pkg)}
                                                >
                                                    {pkg.packaging} {pkg.pkgUnit}
                                                </button>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="product-price theme-color text-center mt-2">
                                        <p><b>&#8377; {item.packagingOptions && item.packagingOptions[0].price}</b></p>
                                    </div>
                                    <div className="product-view-button text-center mt-2">
                                        <button type="button" className="btn btn-light" onClick={() => addProduct(item.id)}>Add</button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
};

export default Product;
