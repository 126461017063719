export class DateTimeUtil {
    // Get today's date as a Date object
    static today() {
      return new Date();
    }
  
    // Get today's date in dd/mm/yyyy format
    static todayDate() {
      const today = new Date();
      return this.formatDate(today);
    }
  
    // Get current time in hh:mm:ss format
    static todayTime() {
      const today = new Date();
      return this.formatTime(today);
    }
  
    // Convert timestamp to date in dd/mm/yyyy format
    static timestampToDate(timestamp) {
      const date = new Date(timestamp);
      return this.formatDate(date);
    }
  
    // Convert timestamp to time in hh:mm:ss format
    static timestampToTime(timestamp) {
      const date = new Date(timestamp);
      return this.formatTime(date);
    }
  
    // Convert timestamp to time in hh:mm AM/PM format
    static timestampToTimeAMPM(timestamp) {
      const date = new Date(timestamp);
      return this.formatTimeAMPM(date);
    }
  
    // Convert date in dd/mm/yyyy format to yyyy/mm/dd format
    static dateToISOFormat(dateString) {
        const [day, month, year] = dateString.split('/');
        return `${year}/${month}/${day}`;
    }
    
    // Convert timestamp to yyyy/mm/dd format
    static timestampToISODate(timestamp) {
        if (!(timestamp instanceof Date) && isNaN(timestamp)) {
          console.error("Input must be a valid timestamp or Date object", timestamp);
          return null;
        }
    
        const date = new Date(timestamp);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
        const day = String(date.getDate()).padStart(2, "0");
        
        return `${year}/${month}/${day}`;
      }
    
    // Convert date in dd/mm/yyyy format to timestamp
    static dateToTimestamp(dateString) {
      const [day, month, year] = dateString.split('/');
      const date = new Date(`${month}/${day}/${year}`);
      return date.getTime();
    }
  
    // Helper function to format date to dd/mm/yyyy
    static formatDate(date) {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    }
  
    // Helper function to format time to hh:mm:ss
    static formatTime(date) {
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');
      return `${hours}:${minutes}:${seconds}`;
    }
  
    // Helper function to format time to hh:mm AM/PM
    static formatTimeAMPM(date) {
      let hours = date.getHours();
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const ampm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      const strTime = `${hours}:${minutes} ${ampm}`;
      return strTime;
    }
  }
  